export const onViewport = (element: Element) => {
	const observer = new IntersectionObserver((observerEntries, intersectionObserver) => {
		const observerEntry = observerEntries[0];
		if (!observerEntry.isIntersecting) {
			return;
		}

		intersectionObserver.unobserve(element);

		observerEntry.target.dispatchEvent(new CustomEvent('enterViewport'));
	}, { threshold: 0.75 });

	observer.observe(element);
};
